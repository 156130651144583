<template>
  <I18nLink
    :to="{
      name: name,
      params: {
        slug: slug,
        secondaryslug: (localizedData?.slugs?.current_locale || localizedData?.slugs?.i18n_slug_en) || localizedData?.slug,
      }
    }"
    :locale="localizedData?.slugs?.i18n_slug_en && !localizedData?.slugs?.current_locale ? 'en' : currentLocale"
  >
    <slot />
  </I18nLink>
</template>

<script setup>
const route = useRoute()
const { locale: currentLocale } = useI18n()
const { slug: slugFromRoute, secondaryslug: secondarySlugFromRoute } = route.params

const props = defineProps({
  name: String,
  localizedData: Object
})

const slug = computed(() => {
  if (slugFromRoute && props?.name?.includes('secondaryslug')) {
    return slugFromRoute
  }
  return (props?.localizedData?.slugs?.current_locale || props?.localizedData?.slugs?.i18n_slug_en) || props?.localizedData?.slug
})
</script>
